import React from 'react'
import Layout from 'elements/Frame/Layout'
import Title from 'elements/Typography/Title'

export default function FeaturesPage () {
  return (
    <Layout>
      <Title>Features</Title>
    </Layout>
  )
}
