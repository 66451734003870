import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Heading } from '@chakra-ui/core'
import LayoutContext from 'contexts/LayoutContext'
import styled from '@emotion/styled'
import css from '@styled-system/css'

const StyledHeading = styled(Heading)(
  css({

    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      bottom: '-10px',
      left: 0,
      borderRadius: '3px',
      width: '50px',
      height: '6px',
      backgroundColor: 'silver'
    }
  })
)

function Title ({ children, label }) {
  const { setTitle } = useContext(LayoutContext)
  useEffect(() => {
    setTitle(label || children)
  }, [children, label, setTitle])

  return (
    <StyledHeading mb="10" as="h1" size="xl" position="relative" >{children}</StyledHeading>
    // <Heading textAlign="left" mb={10} as="h1" size="xl">
    //   {children}
    // </Heading>
  )
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string
}

export default Title
